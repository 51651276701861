.snsBtnlist {
  display: inline-flex;
  gap: 0.8rem;
  margin-top: 1.2rem;
}
.snsBtn {
  background: var(--grayLv3);
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
