@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
/* reset ------------------------------------------------------- */
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
ul,
ol,
li {
  padding: 0;
  margin: 0;
}
button,
input {
  box-sizing: border-box;
  font: inherit;
  color: inherit;
}
button {
  background: none;
  padding: 0;
  border: 0;
  vertical-align: middle;
}
button:enabled {
  cursor: pointer;
}

ul,
ol,
li {
  list-style: none;
}
img,
svg {
  vertical-align: top;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

address {
  font: inherit;
}
dd {
  margin-inline-start: 0;
}
pre {
  overflow-x: auto;
}

/* 공통 ------------------------------------------------------- */
.a11y-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.max-width {
  width: 119rem;
  margin: auto;
  max-width: calc(100% - 4rem);
}
.sl-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multi-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

/* 색상  ------------------------------------------------------- */

:root {
  --primary: #2e6ff2;
  --primaryA11y: #2861d3;
  --background: #fff;
  --surface: #121314;
  --grayLv1: #f3f5fa;
  --grayLv2: #d9dbe0;
  --grayLv3: #8d9299;
  --grayLv3A11y: #6c7074;
  --grayLv4: #47494d;
  --activation: #dee8ff;
  --EffectShadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
}

/* 폰트*/
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  min-width: 320px;
  font-family: "Pretendard";
  letter-spacing: -0.01em;
  min-height: 100vh;
  overflow-y: scroll; /* 수직 스크롤바 항상 표시 */
}
