.layout_nav {
  padding-left: 5rem;
}
.breadcrumb {
  display: flex;
  gap: 0.4rem;
  font-size: 1.4rem;
  background-color: var(--background);
}
.breadcrumb li {
  display: flex;
  align-items: center;
  height: 5rem;
}
.breadcrumb li a {
  font-size: 1.4rem;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  outline-offset: -1px;
}
.breadcrumb li:not(:first-child)::before {
  content: ">";
  margin-right: 0.4rem;
}
@media (hover: hover) {
  .breadcrumb a:hover {
    background-color: var(--activation);
  }
}
.breadcrumb li:last-child a {
  font-weight: bold;
}
