.section {
  position: relative;
  width: 100%;
  height: 60rem;
  background-color: var(--activation);
}

.banner {
  max-width: 1190px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  align-items: center;
}
.banner > div {
  z-index: 100;
  padding: 0 5rem;
}

.banner_title {
  font-size: 4rem;
  margin-bottom: 1.2rem;
}
.banner_title span {
  color: var(--primary);
}
.banner_intro {
  font-size: 1.8rem;
  margin-bottom: 3.2rem;
}

.banner_img {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .banner {
    flex-direction: column;
    text-align: center;
    padding-top: 5.2rem;
  }
  .banner_title {
    font-size: 3.6rem;
  }
  .banner_intro {
    margin-bottom: 2.4rem;
  }
  .banner_img {
    width: 100%;
    max-width: 60rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 767px) {
  .section {
    height: 44rem;
  }
  .banner {
    flex-direction: column;
    text-align: center;
    padding-top: 5.2rem;
  }
  .banner_title {
    font-size: 2.4rem;
  }
  .banner_intro {
    font-size: 1.6rem;
    word-wrap: keep-all;
    margin-bottom: 1.2rem;
  }
  .banner_img {
    width: 100%;
    max-width: 35rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
