.side {
  position: sticky;
  top: 7.4rem;
  left: 0;
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 32rem;
  height: calc(100vh - 7.4rem);
  background-color: var(--background);
  box-sizing: border-box;
  border-right: 1px solid var(--grayLv2);
}
.side_title {
  width: 100%;
}
.side_title a {
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 0 2rem;
  font-size: 1.4rem;
  font-weight: 600;
}
.show {
  opacity: 1;
}
.hide {
  opacity: 0;
}
.btnClose {
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;
  width: 3.2rem;
  height: 3.2rem;
}

.btnOpen {
  position: fixed;
  z-index: 200;
  min-width: 4.2rem;
  height: 4.2rem;
  transition: all 0.5s;
  background-color: var(--background);
  margin: 0.4rem 0.4rem 0.4rem 0.4rem;
}

@media screen and (max-width: 1024px) {
  .btnOpen {
    top: 0.8rem;
  }
}

.footer {
  border-top: 1px solid var(--grayLv2);
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--background);
  padding: 2rem 0;
}
.slideIn {
  animation: slideIn 0.3s ease-in;
}
.slideOut {
  animation: slideOut 0.3s ease-in;
}

@keyframes slideIn {
  from {
    margin-left: -32rem;
    margin-right: 0;
  }
  to {
    margin-left: 0;
    margin-right: 0;
  }
}

@keyframes slideOut {
  from {
    margin-left: 0;
    margin-right: 0rem;
  }
  to {
    margin-left: -32rem;
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .btnOpen {
    position: sticky;
    top: 0.4rem;
    box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.04);
    border: 1px solid var(--grayLv2) !important;
    border-radius: 1rem;
  }
  .side {
    position: fixed;
    top: 0;
    z-index: 2000;
    min-width: 23.5rem;
    height: 100vh;
    border-right: 1px solid var(--grayLv2);
    background-color: var(--background);
  }
  .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    z-index: 1999;
    pointer-events: auto;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검은 배경 */
    z-index: 998; /* 사이드바 보다 한 층 아래에 위치 */
    display: none; /* 기본적으로 숨김 */
    pointer-events: none; /* overlay 위에서의 클릭 이벤트 무시 */
  }

  .overlay.active {
    display: block; /* 사이드바가 열릴 때 overlay를 표시 */
  }
}
