.sub {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  background-color: var(--background);
}

.sub__content {
  flex-grow: 1;
  background-color: var(--background);
}

@media screen and (max-width: 1024px) {
  .sub {
    display: block;
  }
}
.content__wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 4rem;
}
.content__wrap .main {
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
}
.content__wrap aside {
  flex-shrink: 0;
}

/* contents */
.main {
  font-size: 1.125em;
  line-height: 1.8;
}

.main.title {
  font-size: 2.22em;
  border-bottom: 2px solid var(--grayLv2);
  padding-bottom: 0.2em;
  margin-bottom: 0.8em;
  line-height: 1.4;
  letter-spacing: -0.02em;
  word-break: break-all;
}
