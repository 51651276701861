.aside {
  position: sticky;
  top: 15.6rem;
  flex-shrink: 0;
  height: fit-content;
}

.aside button {
  padding: 0;
  border-radius: 1rem;
}

.aside button img {
  width: 2.4rem;
}

.aside .btnClose {
  position: absolute;
  top: 0.9rem;
  right: 0.9rem;
  width: 3.2rem;
  height: 3.2rem;
}

.aside .btnClose img {
  rotate: 180deg;
}

.btnOpen {
  width: 4.2rem;
  height: 4.2rem;
  box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
  border: 1px solid var(--grayLv2);
  background: var(--background);
}

.aside .sublist {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 28rem;
  background: var(--background);
  border: 1px solid var(--grayLv2);
  border-radius: 1rem;
}

.asideTitle {
  flex-shrink: 0;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.4rem;
}

.aside .sublist a:focus {
  position: relative;
  outline-offset: -1px;
  z-index: 10;
}

@media screen and (max-width: 1024px) {
  .aside {
    position: sticky;
    top: 0;
    left: 0;
    width: calc(100% - 7.6rem);
  }
  .aside .sublist {
    width: 100%;
  }
  .btnOpen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
  }
  .btnOpen img {
    margin-right: 1.2rem;
  }

  .rotated {
    transform: rotate(180deg);
  }
}
