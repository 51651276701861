.sub {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 5rem;
  gap: 2rem;
}

@media screen and (max-width: 767px) {
  .content {
    padding: 0;
  }
}
