.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;

  cursor: pointer;
  padding: 1.1rem 2rem;
  border-radius: 1rem;

  background-color: var(--primary);
  color: var(--background);
}

.white {
  background-color: var(--background);
  color: var(--surface);
  border: 1px solid var(--grayLv2);
}
