.toc {
  position: sticky;
  top: 0.4rem;
  flex: 1;
  min-height: 1rem;
  z-index: 100;
  margin: 0.4rem 0.4rem 0.4rem 0.4rem;
  box-sizing: border-box;
}
.tocWrap {
  position: relative;
}

.tocTitle,
.tocBar {
  width: 100%;
  height: 4.2rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 0 1.6rem;
  box-sizing: border-box;
}

.tocBar {
  justify-content: space-between;
  border-radius: 1rem;

  background-color: var(--background);
  border: 1px solid var(--grayLv2);
}

.tocBar img {
  transition: all 0.3s;
}

.opened img {
  transition: all 0.3s;
  transform: rotate(180deg);
}
.positionWrap {
  position: absolute;
  top: 3.5rem;
  width: 100%;
  z-index: 100;
  background-color: var(--background);
  border: 1px solid var(--grayLv2);
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 1rem 1rem;
  padding: 0 1.6rem 0 0;
  box-sizing: border-box;
}

.toc .subBanner {
  margin: auto auto 1.6rem 1.6rem;
}
