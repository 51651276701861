.scroll {
  height: 0.4rem;
  width: 100%;
  background-color: var(--grayLv2);
  position: sticky;
  left: 0;
  z-index: 900;
  top: 7rem;
}

.scroll div {
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  transition: transform 0.3s;
}
/* @media screen and (min-width: 1025px) {
  .scroll {
    top: 7rem;
  }
}
@media screen and (max-width: 1024px) {
  .scroll {
    top: 0;
  }
} */
