.notFound {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100dvh;

  h2 {
    font-size: 4rem;
    color: var(--primary);
    padding: 1.2rem 2rem;
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 3.2rem;
  }

  img {
    width: 30rem;
    height: auto;
  }
}
.btnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
}

@media (max-width: 480px) {
  .notFound {
    margin: auto;
    text-align: center;

    h2 {
      font-size: 2.4rem;
      padding: 0.8rem 1rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }

    img {
      width: 20rem;
    }
  }
}
