.banner {
  margin-top: 1.2rem;
}

.banner a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 8rem;
  padding: 0 2rem;
  border-radius: 1rem;
  position: relative;
}

.banner a h3 {
  font-size: 1.4rem;
}
.banner a p {
  font-size: 1.2rem;
  margin-top: 0.4rem;
  z-index: 10;
}
.banner a img {
  width: auto;
  height: 5.6rem;
  position: absolute;
  top: 1.2rem;
  right: 2rem;
}
