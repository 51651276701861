header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--background);
  box-sizing: border-box;
}

.header_top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1190px;
  height: 7rem;
}

.header_top > a {
  margin: 0 auto 0 2rem;
}

.logo {
  width: 24.2rem;
}

.header_nav,
.nav_list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--surface);
  margin-right: 2rem;
}
.nav_list li {
  cursor: pointer;
}
.active {
  color: var(--primary);
}
.menuBtn {
  width: 4.2rem;
  height: 4.2rem;
  img {
    width: 2.8rem;
    height: 2.8rem;
  }
}

@media (max-width: 1024px) {
  header {
    position: relative;
  }
  .logo {
    width: 17.5rem;
  }
  .header_nav {
    gap: 0.8rem;
  }
  .nav_icon {
    display: flex;
    align-items: center;
  }

  .nav_modal {
    position: absolute;
    top: 7rem;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    display: none;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 22px;
    background-color: var(--background);
    box-shadow: var(--EffectShadow);
    z-index: 2000;
  }

  .nav_modal li {
    padding: 1.7rem 0 1.7rem 2.4rem;
  }

  .nav_modal li:hover {
    background-color: var(--activation);
    color: var(--primary);
  }

  .nav_modal li a {
    display: block;
  }
  .show {
    display: block;
  }
}
